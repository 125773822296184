import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import DownloadView from '@views/DownloadView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Button } from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';

const DownloadWindowsPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  const downloadUrl = `${DOWNLOAD_PAGE}/windows/latest`;

  return (
    <DefaultTemplate>
      <PageMeta
        description={t('pages:downloadPlatformDescription', { platform: 'Windows' })}
        title={t('pages:downloadPlatform', { platform: 'Windows' })}
      />
      <DownloadView
        DownloadButtons={[
          <>
            <Button color="primary" mb={4} renderAs="a" size="medium" href={downloadUrl}>
              {t('common:download')} ({t('64bit')})
            </Button>
            <a href={`${downloadUrl}?arch=32`}>32-bit version</a>
          </>,
        ]}
        platform="windows"
      />
    </DefaultTemplate>
  );
};

export default DownloadWindowsPage;
